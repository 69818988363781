
import { Component, Vue } from 'vue-property-decorator'
import { debounce } from '@panstar/common'
import { Personal, XHeader, XFooter } from '@/layout/components'
import { UserModule } from '@/store/modules'
@Component({
  name: 'Home',
  components: { Personal, XFooter, XHeader },
})
export default class extends Vue {
  btnStyle = {}

  get isLogin() {
    return !!UserModule.token
  }

  getBtnStyle() {
    const width = Math.max(window.innerWidth, 1280)

    return {
      left: '16.6%',
      top: `${56 + (150 * width) / 1280}px`,
    }
  }

  goLogin() {
    this.$router.push({ path: '/login' })
  }

  goContact() {
    this.$router.push({ path: '/contact' })
  }

  resize() {
    return debounce(() => {
      this.btnStyle = this.getBtnStyle()
    }, 100)()
  }

  mounted() {
    this.resize()
    window.addEventListener('resize', this.resize)
  }

  destroyed() {
    window.removeEventListener('resize', this.resize)
  }
}
